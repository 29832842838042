import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'upload-receipt',
    loadChildren: () => import('./upload-receipt/upload-receipt.module').then( m => m.UploadReceiptPageModule)
  },
  {
    path: 'panel',
    loadChildren: () => import('./panel/panel.module').then( m => m.PanelPageModule)
  },
  {
    path: 'panel2',
    loadChildren: () => import('./panel2/panel2.module').then( m => m.Panel2PageModule)
  },
  {
    path: 'screen',
    loadChildren: () => import('./screen/screen.module').then( m => m.ScreenPageModule)
  },
  {
    path: 'panel3',
    loadChildren: () => import('./panel3/panel3.module').then( m => m.Panel3PageModule)
  },
  {
    path: 'home2',
    loadChildren: () => import('./home2/home2.module').then( m => m.Home2PageModule)
  },
  {
    path: 'model',
    loadChildren: () => import('./model/model.module').then( m => m.ModelPageModule)
  },
  {
    path: 'panel4',
    loadChildren: () => import('./panel4/panel4.module').then( m => m.Panel4PageModule)
  },
  {
    path: 'panel5',
    loadChildren: () => import('./panel5/panel5.module').then( m => m.Panel5PageModule)
  },
  {
    path: 'models',
    loadChildren: () => import('./models/models.module').then( m => m.ModelsPageModule)
  },
  {
    path: 'no-refund',
    loadChildren: () => import('./no-refund/no-refund.module').then( m => m.NoRefundPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
